<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="600px" @close="handleClose">
    <el-form ref="formRef" label-width="80px" :model="state.form">
      <el-form-item label="会议名称" prop="meetingName">
        <el-input v-model="state.form.meetingName" disabled />
      </el-form-item>
      <el-form-item label="短信签名" prop="smsSign">
        <el-input v-model="state.form.smsSign" placeholder="暂无短信签名" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">保存</el-button>
    </template>
  </el-dialog>
  <!-- 预览图片 -->
  <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
    <div>
      <el-image :src="dialogImageUrl" />
    </div>
  </el-dialog>
</template>
<script>
export default { name: 'editMeetingManage' }
</script>

<script setup>
import { reactive, ref, getCurrentInstance } from 'vue'
import { meetisShelfOrUpdate } from '@/api/exhibitionManage'
import { debounce } from '@/utils/debounce'
import { upLoadImg } from '@/api/active'
import JsonEditorVue from 'json-editor-vue3'
const props = defineProps({
  title: {
    type: String,
    default: '编辑',
  },
})
const options = ref({
  search: false,
  history: false,
})
const modeList = ref(['text', 'view', 'tree', 'code', 'form']) // 可选模式
const { proxy } = getCurrentInstance()
const dialogFormVisible = ref(false)
const imgList = ref([])
const dialogVisible = ref(false) // 预览图片的开关
const dialogImageUrl = ref('') // 预览图片的src地址
const exStatusList = reactive([
  {
    value: '上架中(上架中的展会，会在展会列表中显示)',
    isShelf: true,
  },
  {
    value: '已下架 (已下架的展会，不再显示。小程序端状态也不改变)',
    isShelf: false,
  },
])
const state = reactive({
  form: {
    meetingName: '',
    exRemark: '',
    meetingCode: '',
    isShelf: false,
    smsSign: '',
    sort: 0,
    pageShareCover: '',
    code: '',
    isHealthMask: '',
    gateProvider: 'WEMEET',
    gateProviderConfig: '',
    isShowOrgHomepage: true,
  },
  providerList: [
    {
      label: '澳龙',
      value: 'INFO_SALONS',
    },
    {
      label: '租达人',
      value: 'ZDR',
    },
    {
      label: 'WeMeet',
      value: 'WEMEET',
    },
    {
      label: '荟源',
      value: 'HUIYUAN',
    },
    {
      label: '志高',
      value: 'ZHIGAO',
    },
    {
      label: '艾科讯',
      value: 'AI_KE_XUN',
    },
  ],
})
const emit = defineEmits(['noticeRefresh'])
const handleJSON = (str) => {
  try {
    const toObj = JSON.parse(str) // json字符串转对象
    if (toObj && typeof toObj === 'object') {
      return true
    }
  } catch (e) {
    console.log(e)
  }
  return false
}
const putExIsShelfOrUnshelf = async () => {
  state.form.gateProviderConfig = JSON.stringify(state.form.providerConfigObj)
  let data = {
    smsSign: state.form.smsSign,
    code: state.form.code,
  }
  // if (
  //   !!state.form.gateProviderConfig &&
  //   !handleJSON(state.form.gateProviderConfig)
  // ) {
  //   proxy.$baseMessage(
  //     `JSON格式化错误，请确认输入格式是否正确`,
  //     'error',
  //     'vab-hey-message-error'
  //   )
  //   return false
  // }
  let resp = await meetisShelfOrUpdate(data)
  proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
  emit('noticeRefresh')
  handleClose()
}
const handleClose = () => {
  dialogFormVisible.value = false
}
const getFormValue = (row) => {
  console.log(row)
  row.sort = row.sort ? Number(row.sort) : 0
  state.form.sort = row.sort
  state.form.meetingName = row?.meetingName || ''
  state.form.exRemark = row?.exRemark || ''
  state.form.smsSign = row?.smsSign || ''
  state.form.code = row?.meetingCode || ''
  state.form.isShelf = row?.isShelf || false
  state.form.pageShareCover = row?.pageShareCover || ''
  state.form.isHealthMask = row?.isHealthMask || true
  state.form.gateProvider = row?.gateProvider || 'WEMEET'
  state.form.gateProviderConfig = row?.gateProviderConfig || ''
  state.form.isShowOrgHomepage = row?.isShowOrgHomepage
  console.log(state.form)

  if (!!state.form.gateProviderConfig) {
    state.form.providerConfigObj = JSON.parse(state.form.gateProviderConfig)
  } else {
    state.form.providerConfigObj = {}
  }

  imgList.value = row?.pageShareCover
    ? [{ name: 'logo', url: row?.pageShareCover }]
    : []
}
const handleOpen = async (row = {}) => {
  // imgList.value = []
  await getFormValue(row)
  dialogFormVisible.value = true
}
const handleChangeExStatus = (e) => {
  state.form.isShelf = e
}
// 上传展会封面
const upImage = async (e) => {
  let upData = new FormData()
  upData.append('file', e.file)
  const { data } = await upLoadImg(upData)
  state.form.pageShareCover = data
}
// 图片预览
const handlePreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}
const handleError = (err, file) => {
  proxy.$baseMessage(
    `文件[${file.raw.name}]上传失败,文件大小为${_.round(
      file.raw.size / 1024,
      0
    )}KB`,
    'error',
    'vab-hey-message-error'
  )
}

const handleExceed = (files) => {
  proxy.$baseMessage(
    `当前限制选择1个文件，本次选择了
               ${files.length}
               个文件`,
    'error',
    'vab-hey-message-error'
  )
}

const handleRemove = (file, fileList) => {
  state.form.pageShareCover = '' // 清除封面值
  imgList.value = [] // 清除数组值
}
// 拿到排序的值
const handleSort = (e) => {
  state.form.sort = e.target.value
}
const handleSave = debounce(() => {
  putExIsShelfOrUnshelf()
})
defineExpose({
  handleClose,
  handleOpen,
})
</script>
